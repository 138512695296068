import humanFormat from 'human-format';
import { filesize } from 'filesize';

export const humanCount = (value: number) => {
  return humanFormat(value, { maxDecimals: 'auto' });
};

export const duration = (seconds: number) => {
  const timeScale = new humanFormat.Scale({
    s: 1,
    m: 60,
    h: 3600,
    days: 86400,
    months: 2592000,
  });
  return humanFormat(seconds, { scale: timeScale });
};

export const numberWithCommas = (x: number) => {
  return numberWithThousandSeparator(x, ',');
};

export const numberWithThousandSeparator = (x: number, separator: string = ',') => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const audioSampleRate = (sampleRate: number) => {
  const hertzScale = humanFormat.Scale.create(['Hz', 'kHz', 'MHz', 'GHz', 'THz'], 1000);
  return humanFormat(sampleRate, { scale: hertzScale });
};

export const humanFileSize = (bytes: number, round: number = 1) => {
  return filesize(bytes, { round: round, symbols: { KB: 'kb', GB: 'gb', MB: 'mb', TB: 'tb' }, spacer: ' ' }) as string;
};

export const GIGABYTE = 1000000000;
export const MEGABYTE = 1000000;
